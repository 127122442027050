// extracted by mini-css-extract-plugin
export var ourMission = "presale-module--ourMission--2CmPR";
export var getTouch = "presale-module--getTouch--ih3y1";
export var main = "presale-module--main--Q4BPz";
export var mainRaised = "presale-module--mainRaised--2nu2U";
export var paddingMain = "presale-module--paddingMain--2qOc7";
export var container = "presale-module--container--X6MoV";
export var mobileFlex = "presale-module--mobileFlex--l8EoU";
export var contactUs = "presale-module--contactUs--3JG-r";
export var statement = "presale-module--statement--2QODX";
export var space150 = "presale-module--space150--3L4yC";
export var space90 = "presale-module--space90--1x0Ny";
export var space45 = "presale-module--space45--3fWPa";
export var header = "presale-module--header--2BSJh";
export var mobileSpace = "presale-module--mobileSpace---4Frq";
export var blueBanner = "presale-module--blueBanner--3RLs4";