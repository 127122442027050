import React from "react"
import CognitoForm from "../../../../components/CongnitoForm"
import Layout from "../../../../components/layout"
import SEO  from "../../../../components/seo"

const styles = require('../../../../styles/presale/presale.module.css')
const classNames = require('classname')

const Rhino: React.FC = () => {

      return (
        <Layout>
            <SEO title="Gamecock Football Parking"/>
            <div className={styles.container}>
                <CognitoForm></CognitoForm>
            </div>
        </Layout>
      );
}

export default Rhino

