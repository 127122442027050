import React, { useEffect} from "react"
import Helmet from "react-helmet"


//<div class="cognito">
//<script src="https://www.cognitoforms.com/s/agx26AfKfE6QG9pw35jQiw"></script>
//<script>Cognito.load("forms", { id: "2" });</script>
//</div>
//<script src="https://www.cognitoforms.com/f/seamless.js" data-key="agx26AfKfE6QG9pw35jQiw" data-form="2"></script>

const CognitoForm : React.FC = () => {
    useEffect(() => {    
        const scriptTag = document.createElement('script');
        scriptTag.onload = () => afterCognitoLoad();
        const cform = document.getElementById('cform')
        if(cform != null) cform.appendChild(scriptTag);
        scriptTag.src = "https://www.cognitoforms.com/s/agx26AfKfE6QG9pw35jQiw";
    }, []);

    function afterCognitoLoad() {
        const cform = document.getElementById('cform')
        const scriptTag2 = document.createElement('script');
        var inlineScript = document.createTextNode("Cognito.load(\"forms\", { id: \"4\" });");
        scriptTag2.appendChild(inlineScript);
        if(cform != null)  cform.appendChild(scriptTag2);
    }

    return (
            <div id="cform" className="cognito" >
            </div>
    );
}

export default CognitoForm
 